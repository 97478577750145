import {KEYSETS} from './consts';
import TJSON from './tjson';
import {TJSonTextTranslation} from './types';

class I18N {
    protected static instance: I18N = new I18N();
    protected tjson: TJSON | null = null;

    constructor() {
        if (I18N.instance) {
            throw new Error('i18n error: use I18N.getInstance() instead of new');
        }
    }

    public static getInstance(): I18N {
        return I18N.instance;
    }

    public import(translations: TJSonTextTranslation, language: string) {
        this.tjson = null;
        this.tjson = I18N.createTJSON(translations, language);
        return true;
    }

    public print(key: Parameters<TJSON['print']>[0], options?: Parameters<TJSON['print']>[1]) {
        if (!this.tjson) {
            return key;
        }

        const {keyset = KEYSETS.FRONTEND} = options || {};

        return this.tjson.keyset(keyset).print(key, {
            ...options,
            keyset,
        });
    }

    private static createTJSON(data: TJSonTextTranslation, language: string) {
        return new TJSON(data).lang(language);
    }
}

const i18n = I18N.getInstance();

export {DEFAULT_LANGUAGE} from './consts';
export default i18n;
