import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {configApi} from 'api/configs';
import {Store, actions} from 'reducers';
import i18n from 'utils/localization/i18n';
import {TJSonTextTranslation} from 'utils/localization/types';

import {useTypedParams} from './useQueryParams';

export const useSetupForm = () => {
    const [configsReady, setConfigsReady] = useState(false);
    const [storeReady, setStoreReady] = useState(false);
    const {lang, phone} = useTypedParams();
    const dispatch = useDispatch();
    const env = useSelector<Store>(state => state.app?.env);

    const setConfigs = async () => {
        let response;

        try {
            response = await configApi.getConfig();
        } catch (err) {
            console.error(err.response.data.message);
        }

        const configs = response.data.appData.configs;

        window.serverData.authproxyHost = response.data.appData.env === 'production'
            ? 'https://cc-api.taxi.yandex-team.ru'
            : 'https://cc-api.taxi.tst.yandex-team.ru';
        window.serverData.backendConfigs = configs || {};
        window.serverData.login = response.data.appData.user.login;
        window.serverData.env = response.data.appData.env;
        window.serverData.configs3 = configs || {};
        dispatch(actions.setAppInfo({
            app: {
                env: response.data.appData.env,
            },
        }));

        setConfigsReady(true);
    };

    useEffect(() => {
        if (!env) {
            return;
        }
        const ccOrigin = env === 'production' ? 'https://calltech.yandex-team.ru' : 'https://calltech.tst.yandex-team.ru';

        window.addEventListener('message', event => {
            if (!event.origin.includes(ccOrigin)) {
                return;
            }

            const lastData = event.data?.args?.at(-1)?.call;

            if (!lastData || !lastData.info) {
                return;
            }

            dispatch(actions.setAppInfo({
                callInfo: {
                    ...lastData.info,
                    endTime: lastData.isEnded ? Date.now() : undefined,
                },
                mockPhone: {phone: lastData.info.phone},
                call: {
                    isEnded: lastData.isEnded,
                    isHold: lastData.onHold,
                    isMuted: lastData.isMuted,
                    isOutgoing: lastData.isOutgoing,
                },
            }));
            setStoreReady(true);
        });

        if (window.self === window.top) {
            window.dispatchEvent(new MessageEvent('message', {data: {args: [
                {
                    call: {
                        onHold: false,
                        info: {
                            phone,
                            mockPhone: {phone},
                            app: {
                                permissions: {},
                            },
                        },
                    },
                },
            ]},
            origin: ccOrigin}));
        }

        window.parent.postMessage({calltech: 'v1', method: 'getState', args: []}, ccOrigin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, env]);

    useEffect(() => {
        window.serverData.lang = lang || 'ru';
        i18n.import(window.translations as TJSonTextTranslation, lang || 'ru');
        delete window.translations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setConfigs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {isReady: configsReady && storeReady};
};
