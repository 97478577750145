import {useEffect, useRef} from 'react';

export const useRefreshAuth = () => {
    const refreshAuth = () => fetch('https://passport.yandex-team.ru/auth/update/', {credentials: 'include', mode: 'no-cors'});
    const interval = useRef<NodeJS.Timeout>();

    useEffect(() => {
        setTimeout(() => refreshAuth(), 2000);
        interval.current = setInterval(() => {
            refreshAuth();
        }, 20 * 60 * 1000);
    }, []);
};
